
.vimeoEmbedWrap {
  padding:56.25% 0 0 0;position:relative;
}

.vimeoEmbed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.copy a {
  @apply text-red-500;
  text-decoration: none;
}

.copy a:hover {
  text-decoration: underline;
}

.copy .giveaway {
  background-color: rgba(206, 3, 76, 0.33);
}

.copy .giveaway a {
  text-decoration: underline;
  @apply text-red-500;
}
