@keyframes float {
  0%, 100% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
}

.clergy {
  
}
.clergy a {
  @apply text-red-500;
  text-decoration: none;
}
.clergy a:hover {
  @apply text-red-500;
  text-decoration: underline;
}

.gawdEyeGolden {
  @apply absolute left-10 top-12 w-1/3 md:top-10 md:w-1/6 md:left-1/3;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.gawdEyeReptilian {
  @apply absolute bottom-5 md:bottom-12 -left-5 w-1/5 md:w-1/12 md:top-32 ;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  animation-delay: 1s;
}

.gawdEyeSlime {
  @apply absolute -right-10 top-28 w-1/2 md:top-40 md:w-1/5;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  animation-delay: 0.5s;
}

.gawdEyeBlue {
  @apply absolute bottom-5  w-1/2 md:-bottom-10 md:w-1/5;
  left: 50%;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  animation-delay: 2s;
}

