.header {
  composes: small-caps from global;
}

.description {
  @apply text-gray-500 mt-1;
}

.quantity {
  @apply text-red-500;
}

