.donutRing {
  stroke: rgb(31, 41, 55);
}
.donutSegment {
  transform-origin: center;
  stroke: rgb(206, 3, 76);

  transition: all 0.75s ease-out;
}


.roundButton.active .donutRing {
  animation: action-activated 4s;
  animation-iteration-count: 1;
}

.roundButton.pulse .donutRing {
  animation: action-activated 2s;
  animation-iteration-count: infinite;
}

@keyframes action-activated {
  0% {
    stroke: rgb(31, 41, 55);
  }
  10% {
    stroke: #A3FEC2
  }
  100% {
    stroke: rgb(31, 41, 55);
  }
}


.roundButton.loading .donutSegment {
  animation: donutloading 1s;
  animation-iteration-count: infinite;
}

@keyframes donutloading {
  0% {
      stroke-dasharray: 3, 97;
      stroke-dashoffset: 25;
  }
  /* 50% {
    stroke-dasharray: 50, 55;
  } */
  100% {
    stroke-dasharray: 3, 97;
    stroke-dashoffset: 125;
  }
}

.donutHole {
  fill: rgba(0,0,0,0.8);
  transition: all 0.2s ease-out;
}

.roundButton {
  @apply select-none relative rounded-full flex items-center justify-center h-14 w-14 md:h-20 md:w-20 md:text-2xl;
  transition: all 0.5s ease-out;
}

.roundButton > svg {
  @apply absolute;
  z-index: 0;
}

.roundButton:hover .donutHole {
  fill: rgba(0,0,1)
}

.roundButton:focus {
  outline: none;
}

.roundButton.active {
  @apply opacity-100;
}

.roundButton.active span {
  @apply opacity-100;
}

.roundButton.inactive span {
  @apply opacity-10 hover:opacity-40;
}

.roundButton > span {
  @apply absolute rounded-full block inset-0.5 flex items-center opacity-80 justify-center hover:bg-opacity-100 hover:opacity-100 active:bg-opacity-80 active:bg-red-300;
  transition: all 0.2s ease-out;

  /* background-image: conic-gradient(rgb(31, 41, 55) 0 100%, black 0 100%); */
}

.roundButton.loading > span.action {
  @apply opacity-0 hover:bg-opacity-0 hover:opacity-0 bg-opacity-0;
}

.roundButton.loading > span.loader {
  @apply opacity-80 visible;
}

.roundButton > span.loader {
  @apply opacity-0 invisible;
}

/* Gawtchi health/progress bar */
.progressBar {
  @apply relative inline-block bg-gray-500 h-0.5 w-32 animate-fadeIn
}

.progressBar > i {
  @apply absolute left-0 top-0 bottom-0 bg-red-500
}
