.primary {
  composes: primaryButton from global
}

.rainbow {
  animation: colorRotate 10s linear 0s infinite;
}

@keyframes colorRotate {
  from {
    color: #9852EC;
  }
  10% {
    color: #86EFAC;
  }
  50% {
    color: #01BCD3;
  }
  75% {
    color: #CE034C;
  }
  100% {
    color: #9852EC;
  }
}