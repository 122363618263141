.gawdViewerWrapper {
  @apply relative m-auto;
}

.gawdViewer {
  @apply bg-black absolute inset-0 overflow-hidden rounded-full -m-3 z-50 md:m-auto;
}

.gawdViewer canvas {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}
